<template>
    <div>
        <div class="container-form">
            <form action="">
                <div class="container-codigo">
                    <div class="label label-codigo">
                        <span class="codigo"> Código de barras <span class="obrigatorio">*</span> </span>
                    </div>
                    <div class="input input-codigo">
                        <input type="number" autocorrect="off" :value="produto.codigo_de_barras" disabled />
                    </div>
                </div>
                <div class="container container-descricao">
                    <div class="label label-descricao">
                        <span class="descricao"> Descrição </span>
                    </div>
                    <div class="input input-descricao">
                        <input type="text" :value="produto.descricao" disabled />
                    </div>
                </div>
                <div class="container container-validade">
                    <div class="label label-validade">
                        <span class="validade"> Data de validade <span class="obrigatorio">*</span> </span>
                    </div>
                    <div class="input input-validade">
                        <input type="text" :value="dataFormatada(dataValidade)" disabled />
                    </div>
                </div>
                <div class="container">
                    <div class="label label-quantidade">
                        <span class="quantidade"> Preço na loja <span class="obrigatorio">*</span> </span>
                    </div>
                    <div class="input input-quantidade">
                        <input
                            type="number"
                            min="0"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            max="99999"
                            maxlength="5"
                            placeholder="00000"
                            v-model="preco"
                            required
                            @input="validaPreco"
                        />
                    </div>
                    <div v-if="precoIsInvalid" class="mensagemErro">Preço inválido</div>
                </div>
                <div class="container container-quantidade">
                    <div class="label label-quantidade">
                        <span class="quantidade"> Quantidade <span class="obrigatorio">*</span> </span>
                    </div>
                    <div class="input input-quantidade">
                        <input
                            type="number"
                            min="0"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            max="99999"
                            maxlength="5"
                            placeholder="00000"
                            v-model="quantidade"
                            required
                        />
                        <div class="controle">
                            <button class="btn-minus" type="button" @click="decrementQuantidade">
                                <img src="../assets/img/minus.svg" alt="subtrair" />
                            </button>
                            <button class="btn-plus" type="button" @click="incrementQuantidade">
                                <img src="../assets/img/plus.svg" alt="adicionar" />
                            </button>
                        </div>
                    </div>
                    <div v-if="quantidadeIsInvalid" class="mensagemErro">Informação Obrigatória</div>
                </div>
                <div class="container container-produto">
                    <div class="label label-produto">
                        <span class="produto"> Produto </span>
                    </div>
                    <div class="produto-img">
                        <img
                            :src="
                                'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' +
                                produto.codigo_de_barras +
                                '.webp'
                            "
                            @error="replaceByDefault"
                            alt="produto 1"
                        />
                    </div>
                </div>
            </form>
        </div>

        <footer-save @editaInclusao="editaInclusao" @envia-form="enviaForm"></footer-save>

        <transition name="fade">
            <div v-if="showConfirm" class="popUp">
                <div class="tela-mensagem">
                    <div class="cancel-msg">
                        Descartar alterações?
                        <div class="close-cancel" @click="showConfirm = false">
                            <img src="../assets/img/close-black.svg" alt="fechar a modal" />
                        </div>
                    </div>
                    <h3>Todas as alterações serão perdidas</h3>
                    <div class="cancel-btn">
                        <button
                            @click="
                                closeModal;
                                showConfirm = false;
                            "
                            type="button"
                        >
                            Não
                        </button>
                        <button @click="descarteProduto()" type="button">Descartar</button>
                    </div>
                </div>
            </div>
        </transition>

        <msg-sucess v-if="showSucess" :mensagemSucess="msgS"></msg-sucess>
        <msg-error v-if="showAlert" :mensagemError="msgE"></msg-error>
    </div>
</template>

<script>
import FooterSave from '../components/FooterSave';
import HTTP from '../http/http.js';
import MsgSucess from '../components/MsgSucess';
import MsgError from '../components/MsgError';
import img from '../assets/img/broken-image.svg';

export default {
    components: {
        FooterSave,
        MsgSucess,
        MsgError,
    },
    data() {
        return {
            produto: null,
            quantidade: '',
            codigo: '',
            dataValidade: '',
            quantidadeIsInvalid: false,
            showConfirm: false,
            showSucess: false,
            showAlert: false,
            msgS: 'Produto editado com sucesso',
            msgE: 'A tarefa não pode ser concluida, tente novamente',
            preco: '',
            precoIsInvalid: false,
        };
    },
    computed: {
        getProduto() {
            return this.$store.getters.getProduto;
        },
    },

    created() {
        let codigo = this.$route.params.codigo;

        this.$store.dispatch('setCodigo', codigo);

        let produtoFinal = this.getProduto;

        this.produto = produtoFinal;
        this.quantidade = this.$route.params.quantidade;
        this.dataValidade = this.$route.params.validade;
        this.preco = this.$route.params.preco;
    },

    methods: {
        dataFormatada(diaDB) {
            if (diaDB != '') {
                let dateSplit = diaDB.split('-');
                let newDate = dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0];
                return newDate;
            } else {
                return '';
            }
        },

        decrementQuantidade() {
            this.quantidade--;
            if (this.quantidade < 0) {
                this.quantidade = 0;
            }
        },

        incrementQuantidade() {
            this.quantidade++;
            if (this.quantidade > 99999) {
                this.quantidade = 99999;
            }
        },

        enviaForm() {
            if (!this.validaFormulario()) return;

            let codigo = this.$route.params.codigo;

            this.$store.dispatch('setCodigo', codigo);

            let produtos = this.getProduto;
            let produtoAtual = produtos.validadesAdicionais.filter((produto) => produto.validade == this.dataValidade);

            let data = {
                codigo_de_barras: this.produto.codigo_de_barras,
                data_validade: this.dataValidade,
                quantidade: this.quantidade,
                preco_venda: this.preco,
            };

            if (navigator.onLine) {
                let loja = sessionStorage.getItem('loja');

                HTTP.patch(`${loja}/promotores`, data)
                    .then(() => {
                        this.showSucess = true;

                        produtoAtual[0].quantidade = this.quantidade;
                        produtoAtual[0].preco_venda = this.preco;
                        produtos.preco_venda = this.preco;

                        setTimeout(() => {
                            this.showSucess = false;
                            this.$router.go(-1);
                        }, 1500);
                    })
                    .catch(() => {
                        /* this.isInvalid = true; */
                        console.error('erro');
                    });
            } else {
                let produtosEditados = JSON.parse(localStorage.getItem('inclusaoEdit'));

                let dataToLS = null;

                if (produtosEditados) {
                    produtosEditados.push(data);

                    dataToLS = JSON.stringify(produtosEditados);
                } else {
                    dataToLS = JSON.stringify([data]);
                }

                this.showSucess = true;

                localStorage.setItem('inclusaoEdit', dataToLS);

                this.$store.dispatch('plusNumeroSync');
            }
        },

        validaFormulario() {
            this.quantidadeIsInvalid = this.quantidade < 0 || this.quantidade == '' ? true : false;

            this.precoIsInvalid = this.preco < 0 || this.preco === '' ? true : false;

            if (this.quantidadeIsInvalid == true || this.precoIsInvalid == true) {
                return false;
            }

            return true;
        },

        validaPreco() {
            this.precoIsInvalid = this.preco <= 0 ? true : false;
        },

        editaInclusao() {
            this.showConfirm = true;
        },

        fechaAlert() {
            setTimeout(() => {
                this.showAlert = false;
            }, 2000);
        },

        fechaSucess() {
            setTimeout(() => {
                this.showSucess = false;
            }, 1500);
        },

        descarteProduto() {
            this.$router.go(-1);
        },

        replaceByDefault(e) {
            e.target.src = img;
        },
    },
};
</script>

<style scoped>
.container-form {
    margin-top: 56px;
    padding: 15px;
    margin-bottom: 66px;
}

.label span {
    font-size: 0.83rem;
}

.obrigatorio {
    color: var(--vermelho);
}

form div div span {
    color: var(--cinza-escuro);
}

input {
    border: 1px solid #e8eaed;
    border-radius: 4px;
    font-size: 0.85rem !important;
    padding: 12px;
    width: 100%;
    text-overflow: ellipsis;
    outline-color: var(--verde);
}

input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.input-codigo,
.input-quantidade {
    display: flex;
    align-items: center;
    position: relative;
}

.input-quantidade {
    border: 1px solid var(--cinza);
    border-radius: 4px;
}

.input-quantidade input {
    border: none;
    text-align: center;
    padding: 14px 8px;
}

.input {
    margin-top: 5px;
    align-items: center;
}

.container {
    margin-top: 30px;
}

.controle {
    display: flex;
    left: 0;
    right: 0;
    justify-content: space-between;
    align-items: center;
}

.controle button {
    padding: 0rem 0.55rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
}

.btn-plus {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.btn-minus {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.produto-img {
    display: flex;
    margin-top: 9px;
    margin-bottom: 15px;
    border: 1px solid #bdc1c6;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.produto-img img {
    max-width: 200px;
    min-width: 100px;
}

.mensagemErro {
    color: var(--vermelho);
    font-size: 0.7rem;
    margin-top: 5px;
}

.tela-over {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.label-float {
    position: relative;
    padding-top: 13px;
}

.label-float input {
    border: 1px solid var(--cinza);
    border-radius: 4px;
    min-width: 180px;
    font-size: 16px;
    transition: all 0.1s ease-out;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    -webkit-appearance: none;
}

.label-float input::placeholder {
    color: transparent;
}

.label-float label {
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 20px;
    margin-top: 13px;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
}

.label-float input:required:invalid + label {
    color: var(--cinza);
}

.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
    font-size: 13px;
    margin-top: -5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #fff;
    color: var(--vermelho);
}

.container-save {
    width: 100%;
    border-top: 1px solid var(--cinza);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.label {
    display: flex;
    align-items: center;
}

.tooltip {
    position: relative;
    display: flex;
    margin-left: 10px;
    cursor: pointer;
}

.tooltip-validade {
    margin-left: 5px;
}

.tooltip img {
    width: 18px;
    height: 18px;
}

.tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 0;
    top: 20%;
    right: 0;
    margin: 18px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 10px;
}

.tela-mensagem h3 {
    padding: 5px 15px;
    font-size: 15px;
}

.cancel-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 20px;
    font-weight: bold;
}

.close-cancel {
    display: flex;
    background-color: #d3d3d3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.close-cancel:hover {
    border-radius: 50%;
    transition: all 0.2s;
    background-color: #f4f4f4;
    border: 1px solid #d3d3d3;
}

.cancel-btn {
    margin-top: 30px;
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.cancel-btn button {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn button:hover {
    position: relative;
    top: 5px;
    background-color: var(--vermelho);
    color: #fff;
}

@media (max-width: 319px) {
    .cancel-msg {
        font-size: 16px !important;
    }

    .tela-mensagem h3 {
        text-align: center;
    }
}

@media (max-height: 610px) {
    .cancel-msg {
        font-size: 18px;
    }

    .cancel-btn {
        margin-top: 5%;
    }
}
</style>
