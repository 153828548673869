<template>
    <div class="popUp">
        <div class="tela-mensagem">
            <div class="sucess">
                <img src="../assets/img/check-white.svg" alt="imagem de check" />
            </div>
            <div class="sucess-msg">
                {{ mensagemSucess }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mensagemSucess: {
            type: String,
        },
    },
};
</script>

<style scoped>
.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 10%;
    top: 15%;
    bottom: 15%;
    right: 10%;
    margin: 20px;
    background-color: #fff;
    border-radius: 6px;
}

.sucess {
    background-color: var(--verde);
    padding: 10px 20px;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.sucess img {
    width: 80px;
}

.sucess-msg {
    padding: 10px;
    height: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}

@media (min-width: 768px) {
    .tela-mensagem {
        left: 20%;
        right: 20%;
    }
    .sucess-msg {
        font-size: 22px;
    }
}
</style>
